// Аутентификация пользователя
const urlDef = `/`;
const uriSession = `/session`;
const urlSignIn = `${uriSession}/signIn`;
const urlSignOut = `${uriSession}/signOut`;

// Домашняя страница
const urlHome = `/home`;

// Недавние пациенты
const urlRecent = `/recent`;

// Приемо-сортировочное отделение
const urlPatientRegistration = `/registration`;
const urlForma200 = `${urlPatientRegistration}/forma200`;
const urlForma300 = `${urlPatientRegistration}/forma300`;
const urlFormaDiseases = `${urlPatientRegistration}/formaDiseases`;
const urlFormaTrauma = `${urlPatientRegistration}/formaTrauma`;

// Журнал личного состава
const urnJournalOfPersonnel = `/journalOfPersonnel`;

// Экспорт
const urlExport = `/export`;

// История заболеваний
const urlHistory = `/history`;

// Эвакуационное отделение
const urlEvacuation = `/evacuation`;

// Управление сервером
const urlManagement = `/management`;
const urlMilitaryUsers = `${urlManagement}/users`;
const urlMilitaryOrganizations = `${urlManagement}/organizations`


export{
    urlHome,
    urlDef, urlSignIn, urlSignOut,    
    urlPatientRegistration, urlForma200, urlForma300, urlFormaDiseases, urlFormaTrauma,
    urnJournalOfPersonnel,
    urlExport,
    urlHistory,
    urlRecent,
    urlManagement, urlMilitaryUsers, urlMilitaryOrganizations,
    urlEvacuation,
}