import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { UserCheck } from '../common/helper/Cheking';
import SelectorForms from '../widgets/SelectorForms';
import FormaByPatient200 from '../widgets/FormaByPatient200';
import FormaByPatient300 from '../widgets/FormaByPatient300';
import FormaByPatientDiseases from '../widgets/FormaByPatientDiseases';
import FormaByPatientTrauma from '../widgets/FormaByPatientTrauma';


export default function PRegistration(props) {
    const navigate = useNavigate();

    useEffect(() => {
        UserCheck(navigate);
    }, []);

    return (
        <>
            {(() => {
                switch (props.step) {
                    case 10:
                        return <FormaByPatientDiseases />
                    case 20:
                        return <FormaByPatientTrauma />
                    case 200:
                        return <FormaByPatient200 />
                    case 300:
                        return <FormaByPatient300 />
                    default:
                        return <SelectorForms />
                }
            })()}
        </>
    )
}