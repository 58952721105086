import React, { memo, useEffect, useState } from "react";

import { ruRU } from '@mui/x-data-grid/locales';
import { useDispatch, useSelector } from "react-redux";

import dayjs from 'dayjs';
import {
    Box,
    Card,
    Grid,
    Typography,
} from "@mui/material";


import { alertSuccess, alertWarn, alertError, alertInfo } from '../../common/helper/ToastAlert';
import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
    GridToolbar,
    GridActionsCellItem,
    GridRowEditStopReasons,
    useGridApiRef,
} from '@mui/x-data-grid';


import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';

import {
    sendGetEvacuationData,
    sendSaveSelected,
    sendSaveRow,
    selectEvacuationData,
    selectLoading,
} from "../evacuation/evacuationSlice";

import { fields, buttons, dialogs } from '../../app/thema';

function CustomToolbar({ selected = [], handleSave = () => { } }) {
    console.log(selected);
    return (
        <GridToolbarContainer>
            <Button
                variant="outlined"
                size="small"
                disabled={!selected.length}
                onClick={handleSave}
            >Завершить эвакуацию {`${selected.length ? ': ' + selected.length : ""}`}</Button>
            <Box sx={{ flexGrow: 1 }} />
            <GridToolbarExport
                printOptions={{
                    disableToolbarButton: true,
                }}
                csvOptions={{
                    utf8WithBom: true,
                }}
                slotProps={{
                    tooltip: { title: 'Экспорт' },
                    button: { variant: 'outlined' },
                }}
            />
        </GridToolbarContainer>
    );
}

const EvacuationComponent = () => {
    const dispatch = useDispatch();
    const [rows, setRows] = useState([]);

    const apiRef = useGridApiRef();
    const [rowModesModel, setRowModesModel] = React.useState({});
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
    let evacuationData = useSelector(selectEvacuationData);
    let loading = useSelector(selectLoading);

    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

    let initialRows = [];
    let columns = [];
    let columnsToHide = {};

    if (evacuationData.table) {
        initialRows = evacuationData.table.rows;
        columns = [...evacuationData.table.columns];

        columns.forEach(column => {
            if (column.hidden) {
                columnsToHide[column.field] = false;
            }
        })
    }

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {

        // const editedRow = rows.find((row) => row.h300_id === id);
        const newRow = apiRef.current.getRowWithUpdatedValues(id);

        // console.log(apiRef.current);
        // console.log(editedRow.h300_status, updatedRow.h300_status);


        // if (editedRow.h300_status !== updatedRow.h300_status) {
        //     let dNow = null;

        //     dNow = new Date(dayjs());
        //     dNow = dNow.getFullYear() === 1 ? new Date() : dNow.toISOString();

        //     console.log(dNow);


        //     // const updatedRow2 = apiRef.current.getRowWithUpdatedValues(id);
        //     // console.log(updatedRow2);
        //     setRows((prevRows) => {
        //         return prevRows.map((row, index) => {
        //             console.log(row);
        //             return row.h300_id === id ? { ...row, h300_date_action2: dNow } : row;
        //         }
        //         );
        //     });
        //     apiRef.current.updateRows([{
        //         h300_id: id,
        //         h300_date_action2: dNow,
        //     }]);


        // Проверяем заполнены ли нужные поля
        if (newRow.h300_status === "Ожидает эвакуации") {

            let fields = [
                "h300_diagnosis",
                "h300_hospital_ward",
                "h300_evacuation_type",
                "h300_diagnosis_specification",
                "h300_patient_condition",
                // "h300_mm_department", // ВМУ пока не проверяем
                "h300_referral",
                "h300_location2",
                "h300_date_action2",
            ];

            let errorFields = [];
            fields.forEach(field => {
                if (!newRow[field]) {
                    errorFields.push(field);
                }
            })

            console.log(errorFields);

            if (errorFields.length) {
                alertError(<Box>
                    <Box sx={{ fontWeight: 700 }}>При статусе "Ожидает эвакуации" необходимо заполнить следующие поля:</Box>
                    <ul>
                        {errorFields.map(errorField => {

                            let column = columns.find(item => item.field === errorField)

                            return <li key={errorField}>{column.headerName}</li>;
                        })}
                    </ul>
                </Box>, {
                    autoClose: 5000
                });
            } else {
                setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
            }

        } else {
            setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
        }


    };

    // const handleDeleteClick = (id) => () => {
    //     setRows(rows.filter((row) => row.h300_id !== id));
    // };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const processRowUpdate = (newRow) => {
        const editedRow = rows.find((row) => row.h300_id === newRow.h300_id);

        // Если статус поменялся, обновляем Дату убытия на текущую
        const updatedRow = {
            ...newRow,
            h300_date_action2: editedRow.h300_status !== newRow.h300_status ? new Date() : newRow.h300_date_action2,
        };

        setRows(rows.map((row) => (row.h300_id === updatedRow.h300_id ? updatedRow : row)));


        // Формируем строку с датой, для бэка
        let d = null;
        if (updatedRow.h300_date_action2) {
            d = new Date(dayjs(updatedRow.h300_date_action2 ?? undefined));
            d = d.getFullYear() === 1 ? new Date() : d.toISOString();
        }

        let rowToSave = {
            ...newRow,
            h300_date_action2: d,
        }




        dispatch(sendSaveRow({ row: rowToSave }))
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    columns.push({
        field: 'actions',
        type: 'actions',
        headerName: '',
        width: 90,
        cellClassName: 'actions',
        getActions: ({ id }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

            if (isInEditMode) {
                return [
                    <GridActionsCellItem
                        icon={<SaveIcon />}
                        label="Сохранить"
                        sx={{
                            color: 'primary.main',
                        }}
                        onClick={handleSaveClick(id)}
                    />,
                    <GridActionsCellItem
                        icon={<CancelIcon />}
                        label="Отменить"
                        className="textPrimary"
                        onClick={handleCancelClick(id)}
                        color="inherit"
                    />,
                ];
            }

            return [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Редактировать"
                    className="textPrimary"
                    onClick={handleEditClick(id)}
                    color="inherit"
                />,
                // <GridActionsCellItem
                //     icon={<DeleteIcon />}
                //     label="Delete"
                //     onClick={handleDeleteClick(id)}
                //     color="inherit"
                // />,
            ];
        },
    },)


    const handleSaveSelected = () => {
        dispatch(sendSaveSelected({
            selected: rowSelectionModel, success: () => {
                setRowSelectionModel([]);
                dispatch(sendGetEvacuationData());
            }
        }));
        console.log(rowSelectionModel);
    }




    useEffect(() => {
        dispatch(sendGetEvacuationData());
    }, [dispatch]);

    useEffect(() => {
        let columnsToHide = {};

        if (evacuationData.table) {
            columns.forEach(column => {
                if (column.hidden) {
                    columnsToHide[column.field] = false;
                }
            })
            setColumnVisibilityModel(columnsToHide)

            initialRows = evacuationData.table.rows;
            setRows(initialRows);
        }
    }, [evacuationData.table]);


    return <Grid
        className="no-print"
        sx={{
            mt: '15px',
        }}
        container
    >

        <Grid
            item
            xs={12}
            md={12}
            lg={12}
        >
            <form>
                <Card
                    sx={{
                        mx: '5px',
                        py: '15px',
                        pl: '10px',
                        pr: '40px',
                        borderRadius: '10px'
                    }}
                    variant="outlined"
                >
                    <Grid
                        container spacing={2}
                    >
                        <Grid
                            style={{
                                textAlign: 'center',
                            }}
                            item
                            xs={12}
                        >
                            <Typography
                                variant="h4"
                                color={fields.collection2.borderColor}
                            >
                                Эвакуационное отделение
                            </Typography>

                        </Grid>

                        <Box sx={{
                            height: "calc(100vh - 150px)",
                            width: '100%',
                            pl: 3,
                            pr: 4,
                            '& .editable-cell input': {
                                backgroundColor: 'rgba(224, 183, 60, 0.45)',
                                color: '#1a3e72',
                            },
                        }}>
                            <DataGrid
                                apiRef={apiRef}
                                getRowId={(row) => row.h300_id}
                                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                                rows={rows}
                                columns={columns}
                                editMode="row"
                                rowModesModel={rowModesModel}
                                onRowModesModelChange={handleRowModesModelChange}
                                onRowEditStop={handleRowEditStop}

                                processRowUpdate={processRowUpdate}
                                onRowSelectionModelChange={(newRowSelectionModel) => {
                                    setRowSelectionModel(newRowSelectionModel);
                                }}
                                rowSelectionModel={rowSelectionModel}
                                slots={{
                                    toolbar: CustomToolbar.bind(this, { selected: rowSelectionModel, handleSave: handleSaveSelected }),
                                }}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 100,
                                        },
                                    },
                                }}
                                isRowSelectable={(params) => params.row.h300_status && params.row.h300_status.toLowerCase() === "ожидает эвакуации"}
                                pageSizeOptions={[10, 50, 100]}
                                checkboxSelection
                                disableRowSelectionOnClick
                                loading={loading}
                                density="compact"
                                columnVisibilityModel={columnVisibilityModel}
                                onColumnVisibilityModelChange={(newModel) =>
                                    setColumnVisibilityModel(newModel)
                                }
                                printOptions={{
                                    hideFooter: true,
                                    hideToolbar: true,
                                    // includeCheckboxes: false,
                                }}


                            // columnVisibilityModel={{
                            //     // status: false,
                            //     id: false,
                            //     fio: true,
                            // }}
                            // slotProps={{
                            //     loadingOverlay: {
                            //         variant: 'linear-progress',
                            //         noRowsVariant: 'linear-progress',
                            //     },
                            // }}
                            // experimentalFeatures={true}
                            // autoHeight
                            />
                        </Box>




                    </Grid>
                </Card>
            </form>
        </Grid>


    </Grid>;
}



export const Evacuation = memo(EvacuationComponent);
