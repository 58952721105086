import React, { memo } from "react";
import {  useSelector } from "react-redux";

import {
    Divider,
    Typography,
    Stack,
} from '@mui/material';

import {
    selectPatientData,
} from "../../historySlice";

const PatientInfoComponent = ({ blockStyles = {} }) => {
    const patientData = useSelector(selectPatientData);

    const {
        id,
        suid,
        fio,
        birthday,
        militaryUnitCode,
        militaryUnit,
        militaryFunction,
        militaryRank,
        events
    } = patientData;

    let patientLastRowInfo = [];

    if (militaryUnit) {
        patientLastRowInfo.push(militaryUnit);
    }
    if (militaryFunction) {
        patientLastRowInfo.push(militaryFunction);
    }
    if (militaryRank) {
        patientLastRowInfo.push(militaryRank);
    }

    return <>
        <Stack
            spacing={2}
            sx={{
                textAlign: "center",
                m: 3,
                ...blockStyles,
            }} >
            <Typography variant="h6" sx={{ margin: "0 !important" }}>Личный номер №{suid}</Typography>
            <Typography variant="h5" sx={{ margin: "0 !important" }}>{fio} {birthday ? new Date(birthday).toLocaleDateString() : ""}</Typography>
            <Typography variant="body2" sx={{ margin: "0 !important" }}>{patientLastRowInfo.join(", ")}</Typography>
        </Stack>

        <Divider />
    </>;
}

export const PatientInfo = memo(PatientInfoComponent);
