import React from 'react';
import { Routes, Route } from 'react-router-dom';

import {
    urlDef, urlSignIn, urlSignOut,
    urlHome,
    urlRecent,
    urlPatientRegistration, urlForma200, urlForma300, urlFormaDiseases, urlFormaTrauma,
    urlExport,
    urlHistory,
    urlEvacuation,
    urlManagement, urlMilitaryUsers, urlMilitaryOrganizations,
} from '../common/helper/UrlRouter';

import PAuthentication from '../pages/PAuthentication';
import PMain from '../pages/PMain';
import PRegistration from '../pages/PRegistration';
import PExport from '../pages/PExport';
import PHistory from '../pages/PHistory';
import PEvacuation from '../pages/PEvacuation';
import PRecent from '../pages/PRecent';
import PManagement from '../pages/PManagement';


/**
 * Маршрутизация приложения
 * @returns готовая страница
 */
export default function router() {
    return (
        <Routes>            
            <Route path={urlHome} element={<PMain />} />

            <Route path={urlDef} element={<PAuthentication />} />
            <Route path={urlSignIn} element={<PAuthentication />} />
            <Route path={urlSignOut} element={<PAuthentication />} />

            <Route path={urlRecent} element={<PRecent />} />

            <Route path={urlPatientRegistration} element={<PRegistration step={0} />} />
            <Route path={urlFormaDiseases} element={<PRegistration step={10} />} />
            <Route path={urlFormaTrauma} element={<PRegistration step={20} />} />
            <Route path={urlForma200} element={<PRegistration step={200} />} />
            <Route path={urlForma300} element={<PRegistration step={300} />} />

            <Route path={urlExport} element={<PExport />} />
            <Route path={urlEvacuation} element={<PEvacuation />} />
            <Route path={urlHistory} element={<PHistory />} >
                <Route path={urlHistory+"/:patientId?"} element={<PHistory />} />
            </Route>
            
            <Route path={urlManagement} element={<PManagement step={0}/>} />
            <Route path={urlMilitaryUsers} element={<PManagement step={1}/>} />
            <Route path={urlMilitaryOrganizations} element={<PManagement step={2}/>} />
        </Routes>
    )
}