import React, { memo, useState, useMemo, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
// import { MIN_SEARCH_TEXT, MAX_SEARCH_WORDS } from "constants";

import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import { fields, buttons, dialogs } from '../../../../app/thema';
import {
    Divider,
    Collapse,
    ListItemIcon,
    Box,
} from '@mui/material';


import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';

import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';

import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import PrintIcon from '@mui/icons-material/Print';
import ArrowRightIcon from '@mui/icons-material/Assignment';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Handbook300Async, MilitaryHandbooksAsync } from '../../../../common/api/Handbook';
import { DateTimeDesktopFld, MultilineFld } from '../../../../components/Fields';
import { EndIconBtn } from '../../../../components/Buttons';
import { AutoCompleteCbx } from '../../../../components/ComboBoxs';

import {
    selectCurrentHistoryItem,
    setCurrentHistoryItem,
    selectPatientData,
    updateCurrentHistoryItem,
    updatePatientData,
    sendUpdate300,
} from "../../historySlice";

import { PrintTpl_1 as PrintTpl1 } from "../PrintTpl_1";
import { PrintTpl_2 as PrintTpl2 } from "../PrintTpl_2";
import { PrintTpl_3 as PrintTpl3 } from "../PrintTpl_3";
import { PrintTpl_4 as PrintTpl4 } from "../PrintTpl_4";
import { PrintTpl_5 as PrintTpl5 } from "../PrintTpl_5";

import { PatientInfo } from "../PatientInfo";
import { PrintBlock } from "../PrintBlock";

import "./styles.css";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
    palette: {
        primary: { main: "#344C11" },
    },
});

const model = {
    status: {
        thema: fields.collection2,
        type: '',
        fieldKey: 'status',
        label: 'Статус',
    },
    location: {
        component: AutoCompleteCbx,
        thema: fields.collection2,
        type: 'list',
        fieldKey: 'location',
        label: 'Место оказания медицинской помощи',
    },
    hospitalizationDate: {
        component: DateTimeDesktopFld,
        thema: fields.collection2,
        type: 'date',
        fieldKey: 'hospitalizationDate',
        label: 'Время поступления/обращения',
    },
    militaryRank: {
        component: AutoCompleteCbx,
        thema: fields.collection2,
        type: 'list',
        fieldKey: 'militaryRank',
        label: 'Воинское звание',
    },
    disease: {
        component: MultilineFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'disease',
        label: 'Диагноз при поступлении',
        rows: '4',
    },
    diagnosisСlinical: {
        component: MultilineFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'diagnosisСlinical',
        label: 'Диагноз клинический',
        rows: '4',
    },
    diagnosisСlinicalFinal: {
        component: MultilineFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'diagnosisСlinicalFinal',
        label: 'Диагноз клинический заключительный',
        rows: '4',
    },
    resultOfDiagnostic: {
        component: MultilineFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'resultOfDiagnostic',
        label: 'Результаты диагностических исследований',
        rows: '4',
    },
    helping: {
        component: MultilineFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'helping',
        label: 'Оказана помощь',
        rows: '4',
    },
    pharmacotherapy: {
        component: MultilineFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'pharmacotherapy',
        label: 'Фармакотерапия',
        rows: '4',
    },
    risks: {
        component: MultilineFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'risks',
        label: 'Риски',
        rows: '4',
    },
    dischargeDate: {
        component: DateTimeDesktopFld,
        thema: fields.collection2,
        type: 'date',
        fieldKey: 'dischargeDate',
        label: 'Время эвакуации/выписки',
    },
    evacuationLocation: {
        component: AutoCompleteCbx,
        thema: fields.collection2,
        type: 'list',
        fieldKey: 'evacuationLocation',
        label: 'Куда эвакуирован',
    },
    doctor: {
        component: MultilineFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'doctor',
        label: 'Лечащий врач',
        rows: '1',
    },
}

const ListItemComponent = ({ title, currentHistoryItem, editMode, formik, locationsList, militaryRanksList }) => {
    let key = title.fieldKey;

    const elems = {
        militaryRank: militaryRanksList,
        evacuationLocation: locationsList,
        location: locationsList,
    };

    const handleChangeDate = (key, val) => {
        let d = new Date(dayjs(val ?? undefined));

        d = d.getFullYear() === 1 ? new Date() : d.toISOString();
        formik.setFieldValue(key, d);
    }

    if (!editMode || !model[key] || !model[key].component) {
        return <ListItemButton>
            <ListItemText
                primary={title.label}
                secondary={title.type === "date" && currentHistoryItem[title.fieldKey] ? new Date(currentHistoryItem[title.fieldKey]).toLocaleString(undefined, { timeStyle: "short", dateStyle: "short", }) : currentHistoryItem[title.fieldKey] || "-"}
            />
        </ListItemButton>;
    } else {
        if (model[key]) {
            const FieldComponent = model[key].component;

            return <Box
                sx={{ m: 2 }}>
                <FieldComponent
                    size={'small'}
                    variant={'outlined'}
                    thema={model[key].thema}
                    type={model[key].type}
                    name={key}
                    rows={model[key].rows || ""}
                    value={formik.values[key]}
                    isDisabled={false}
                    label={title.label}
                    handleBlur={formik.handleBlur}
                    handleChange={title.type === "date" ? handleChangeDate : formik.handleChange}
                    touched={formik.touched[key]}
                    error={formik.errors[key]}
                    elems={elems[key] || []}
                    val={formik.values[key]}
                    setFieldValue={formik.setFieldValue}
                    handleSetState={() => { }}
                /></Box>;
        }
    }
}


const HistoryItemComponent = ({ currentEvent, notEnded, isLast }) => {
    const dispatch = useDispatch();
    let currentHistoryItem = useSelector(selectCurrentHistoryItem);
    const patientData = useSelector(selectPatientData);
    const eventData = patientData.events ? patientData.events[currentEvent] : {};
    const [tplId, setTplId] = useState(0);
    const [editMode, setEditMode] = useState(false);

    // const [locationState, setLocationState] = useState({ id: '0', label: "EMPTY" });
    const [locationsList, setLocationsList] = useState([]);
    const [militaryRanksList, setMilitaryRanksList] = useState([]);
    const [openPrint, setOpenPrint] = React.useState(false);

    currentHistoryItem = {
        ...currentHistoryItem,
        militaryRank: patientData.militaryRank
    };

    let printData = {
        patientData,
        currentHistoryItem,
        currentEvent,
    };

    const handleFormAction = (values) => {
    };

    const formik = useFormik({
        onSubmit: handleFormAction,
        validationSchema: Yup.object().shape({}),
        initialValues: {
            dischargeDate: null,
            disease: "", // diagnosis
            evacuationLocation: { id: '', label: '' },
            helping: "",
            hospitalizationDate: null,
            location: { id: '', label: '' },
            status: "",
            doctor: "",
            militaryRank: { id: '', label: '' },
        }
    });

    let fullPrint = false;

    if (notEnded === false && isLast === true) {
        fullPrint = true;
        console.log("fullPrint", fullPrint);
    }

    /**
     * shitfix
     * @description нужно добавить проверку. В условия к fullPrint нужно добавить проверку. Печатать можно всё, если не прошло 24 часа. А пока хардкодим всегда true
     */
    fullPrint = true;


    const handleClose = () => {
        dispatch(setCurrentHistoryItem({}));
        setEditMode(false);
    };

    const handleEdit = () => {
        if (!editMode) {
            Object.keys(model).forEach(key => {
                let value = currentHistoryItem[key] || "";
                value = model[key].type === "list" ? { id: "", label: value } : value;

                formik.setFieldValue(model[key].fieldKey, value);
            });
            setEditMode(true);
        } else {
            setEditMode(false);
        }
    };

    const handleClickSave = () => {
        let saveData = {
            idEvent: eventData.id,
            idHistory: currentHistoryItem.id,
            item: {
                diagnosis: formik.values.disease,
                locationMedicalAssistance: formik.values.location?.label || "",
                locationWhere: formik.values.evacuationLocation?.label || "",
                dateOfVisits: formik.values.hospitalizationDate || null,
                dateOfEvacuation: formik.values.dischargeDate || null,
                helpProvided: formik.values.helping,
                doctor: formik.values.doctor,
                militaryRank: formik.values.militaryRank?.label || "",
                recommendations: currentHistoryItem.recommendations,
                circumstances: eventData.circumstances,
                complaint: eventData.complaint || currentHistoryItem.complaint || "",
                dateOfInjury: eventData.eventDate || null,
                gravityOfInjury: eventData.gravityOfInjury,
                idPersonnel: eventData.idPersonnel,
                locationWhereInjury: eventData.location,
                nature: eventData.nature,

                diagnosisСlinical: formik.values.diagnosisСlinical,
                diagnosisСlinicalFinal: formik.values.diagnosisСlinicalFinal,
                resultOfDiagnostic: formik.values.resultOfDiagnostic,
                pharmacotherapy: formik.values.pharmacotherapy,
                risks: formik.values.risks,
            }
        }

        dispatch(sendUpdate300({
            data: saveData,
            success: () => {
                dispatch(updateCurrentHistoryItem({
                    dischargeDate: formik.values.dischargeDate,
                    disease: formik.values.disease,
                    doctor: formik.values.doctor,
                    evacuationLocation: formik.values.evacuationLocation?.label || "",
                    helping: formik.values.helping,
                    hospitalizationDate: formik.values.hospitalizationDate,
                    location: formik.values.location?.label || "",
                    
                    diagnosisСlinical: formik.values.diagnosisСlinical,
                    diagnosisСlinicalFinal: formik.values.diagnosisСlinicalFinal,
                    resultOfDiagnostic: formik.values.resultOfDiagnostic,
                    pharmacotherapy: formik.values.pharmacotherapy,
                    risks: formik.values.risks,
                }));

                dispatch(updatePatientData({
                    militaryRank: formik.values.militaryRank?.label || "",
                }));
                setEditMode(false);
            },
        }));
    };

    const handleClickPrintMenu = () => {
        setOpenPrint(!openPrint);
    };

    const handleClickPrint = (tpl) => {
        setTplId(tpl);

        setTimeout(() => {
            window.print();
        }, 600);
    }

    const setLocations = (locations) => {
        setLocationsList(locations);
        if (formik.values.location?.label === '') {
            formik.setFieldValue("location", currentHistoryItem.location || "");
        }
        if (formik.values.evacuationLocation?.label === '') {
            formik.setFieldValue("evacuationLocation", currentHistoryItem.evacuationLocation || "");
        }
    }

    const setMilitaryRanks = (militaryRanks) => {
        setMilitaryRanksList(militaryRanks);
        if (formik.values.militaryRank?.label === '') {
            formik.setFieldValue("militaryRank", currentHistoryItem.militaryRank || "");
        }

    }

    const handlerDataLoader = async () => {
        let handbook300 = await Handbook300Async();
        let handbookMilitary = await MilitaryHandbooksAsync();
        console.log(handbookMilitary);

        if (handbook300.result) {
            setLocations(handbook300.result.locationWhere);
        }
        if (handbookMilitary.result) {
            setMilitaryRanks(handbookMilitary.result.militaryRank);
        }
    }

    useEffect(() => {
        handlerDataLoader();
    }, []);

    return <>
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <Dialog
                    fullScreen
                    open={!!currentHistoryItem.id}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    {tplId === 1 ? <PrintTpl1 printData={printData} /> : null}
                    {tplId === 2 ? <PrintTpl2 printData={printData} /> : null}
                    {tplId === 3 ? <PrintTpl3 printData={printData} /> : null}
                    {tplId === 4 ? <PrintTpl4 printData={printData} /> : null}
                    {tplId === 5 ? <PrintTpl5 printData={printData} /> : null}
                    <div className="no-print">
                        <AppBar
                            sx={{
                                position: "relative",
                            }}
                        >
                            <Toolbar
                                sx={{
                                    justifyContent: "space-between",
                                }}>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleClose}
                                    aria-label="close"
                                >
                                    <ArrowBackIcon />
                                </IconButton>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleEdit}
                                    aria-label="close"
                                >
                                    {editMode ? <CancelIcon /> : <EditIcon />}
                                </IconButton>
                            </Toolbar>
                        </AppBar>

                        <PatientInfo blockStyles={{ textAlign: "left" }} />

                        <List>
                            <form onSubmit={formik.handleSubmit}>
                                {
                                    Object.keys(model).map(key => <Fragment
                                        key={key}>
                                        <ListItemComponent
                                            title={model[key]}
                                            currentHistoryItem={currentHistoryItem}
                                            editMode={editMode}
                                            formik={formik}
                                            // setLocationState={setLocationState}
                                            locationsList={locationsList}
                                            militaryRanksList={militaryRanksList}
                                        />
                                        <Divider />
                                    </Fragment>)
                                }
                            </form>
                            {editMode ? <Box
                                sx={{ m: 2 }}>
                                <EndIconBtn
                                    thema={buttons.collection1}
                                    icon={<SaveIcon />}
                                    variant={'contained'}
                                    value={"Сохранить"}
                                    handleClick={handleClickSave}
                                />
                            </Box>
                                :
                                <ListItem>
                                    <List>
                                        <ListItemButton
                                            selected
                                            onClick={handleClickPrintMenu}
                                        >
                                            <ListItemIcon>
                                                <PrintIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="Печать"
                                                sx={{
                                                    pr: 2,
                                                }}
                                            />
                                            {openPrint ? <ExpandLess /> : <ExpandMore />}
                                        </ListItemButton>
                                        <Collapse in={openPrint} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                <ListItemButton
                                                    sx={{ pl: 4 }}
                                                    onClick={() => handleClickPrint(3)}
                                                >
                                                    <ListItemIcon>
                                                        <ArrowRightIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Амбулаторная карта" />
                                                </ListItemButton>
                                                <ListItemButton
                                                    sx={{ pl: 4 }}
                                                    onClick={() => handleClickPrint(4)}
                                                >
                                                    <ListItemIcon>
                                                        <ArrowRightIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Стационарный лист" />
                                                </ListItemButton>

                                                {fullPrint && <ListItemButton
                                                    sx={{ pl: 4 }}
                                                    onClick={() => handleClickPrint(2)}
                                                >
                                                    <ListItemIcon>
                                                        <ArrowRightIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Переводной эпикриз" />
                                                </ListItemButton>}

                                                {fullPrint && <ListItemButton
                                                    sx={{ pl: 4 }}
                                                    onClick={() => handleClickPrint(1)}
                                                >
                                                    <ListItemIcon>
                                                        <ArrowRightIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Выписной эпикриз" />
                                                </ListItemButton>
                                                }
                                                <ListItemButton
                                                    sx={{ pl: 4 }}
                                                    onClick={() => handleClickPrint(5)}
                                                >
                                                    <ListItemIcon>
                                                        <ArrowRightIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Форма 12" />
                                                </ListItemButton>
                                            </List>
                                        </Collapse>
                                    </List>
                                </ListItem>}
                            <Divider />
                        </List>
                    </div>
                </Dialog>
            </ThemeProvider>
        </React.Fragment>
    </>;
}

export const HistoryItem = memo(HistoryItemComponent);
