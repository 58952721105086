import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useLocation } from 'react-router';

import Router from "./routes";

import { AlertToast } from '../components/Alerts';
import { UserCheck } from '../common/helper/Cheking';

import { appStorage } from '../helpers';

import AppBarMenu from '../widgets/AppBarMenu';
import LeftMenu from '../widgets/LeftMenu';

import {
    setCurrentPatientId,
    sendGetPatientData,
} from "../features/history/historySlice";

const titles = {
    history: 'Этапы эвакуации',
    registration: 'Приемно-сортировочное отделение',
    recent: 'Мои пациенты',
    evacuation: 'Эвакуационное отделение',
    management: 'Управление сервером',
    export: 'Экспорт данных',
};

export default function App() {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const params = useParams();
    const [menuOpen, setMenuOpen] = useState(false);
    const [role, setRole] = useState('');

    const getTitleFromPathname = pathname => {
        const pahtArray = pathname ? pathname.split("/") : ["", ""];
        const title = pahtArray[1] ? titles[pahtArray[1]] : "";

        return title ? title.trim() : "";
    }

    useEffect(() => {
        UserCheck(navigate);
        let buf = appStorage.getItem('authentication');
        buf && setRole(buf.role);
    }, []);

    useEffect(() => {
        dispatch(setCurrentPatientId(params.patientId));
        if (params.patientId) {
            dispatch(sendGetPatientData({ id: params.patientId }));
        }
    }, [dispatch, params.patientId]);

    const toggleDrawerOpen = () => {
        setMenuOpen(true);
    };
    const toggleDrawerClose = () => {
        setMenuOpen(false);
    };

    const pageTitle = getTitleFromPathname(pathname);

    return (
        <>
            {pageTitle && <>
                <AppBarMenu
                    headerValue={pageTitle}
                    handleClick={toggleDrawerOpen}
                />
                <LeftMenu
                    open={menuOpen}
                    role={role}
                    handleClick={toggleDrawerClose}
                />
            </>}
            <Router />
            <AlertToast />
        </>
    )
}
