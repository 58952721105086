import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from 'react-router';

import { UserCheck } from '../common/helper/Cheking';
import { History } from '../features/history';

import {
    setCurrentPatientId,
    sendGetPatientData,
} from "../features/history/historySlice";


export default function PHistory(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();

    useEffect(() => {
        UserCheck(navigate);
    }, []);

    useEffect(() => {
        dispatch(setCurrentPatientId(params.patientId));
        if (params.patientId) {
            dispatch(sendGetPatientData({ id: params.patientId }));
        }
    }, [dispatch, params.patientId]);

    return (
        <>
            <History />
        </>
    )
}
