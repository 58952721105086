import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchSendSaveSelected,
  fetchSendSaveRow,
  fetchSendGetEvacuationData,
} from "./evacuationAPI";

// import { appStorage } from "../../helpers";




const initialState = {
  currentPatientId: null,
  currentHistoryItem: {},
  patientEdit: {
    disease: [],
    lifesAnamnez: [],
  },
  patientData: {
    patientInfo: {},
    evacuations: [],
  },
  evacuationData: {},
  loading: {
    evacuationData: false,
  }
};


export const sendGetEvacuationData = createAsyncThunk(
  "evacuation/sendGetEvacuationData",
  async (props = {}, { getState }) => {
    const {
      // id,
      success = () => { }
    } = props;
    // const { auth, history } = getState();

    const response = await fetchSendGetEvacuationData({
      data: {
        // id
        // authKey: auth.authKey,
        // guid: history.resultData?.guid || null
      },
      success
    });

    return response;
  }
);

export const sendSaveSelected = createAsyncThunk(
  "evacuation/sendSaveSelected",
  async (props = {}, { getState }) => {
    const {
      selected,
      success = () => { }
    } = props;
    // const { auth, history } = getState();

    const response = await fetchSendSaveSelected({
      data: {
        guids: selected
      },
      success
    });

    return response;
  }
);

export const sendSaveRow = createAsyncThunk(
  "evacuation/sendSaveRow",
  async (props = {}, { getState }) => {
    const {
      row,
      success = () => { }
    } = props;
    // const { auth, history } = getState();

    const response = await fetchSendSaveRow({
      data: {
        row
      },
      success
    });

    return response;
  }
);



export const evacuationSlice = createSlice({
  name: "evacuation",
  initialState,
  reducers: {

    addToSymptomsList: (state, action) => {
      console.log("addToSymptomsList", action);
      state.symptomsList = [
        ...state.symptomsList,
        action.payload
      ];
    },

    setResultGraphId: (state, action) => {
      state.resultGraphId = action.payload;
    },

    setRASPage: (state, action) => {
      state.RAS.currentPage = action.payload;
    },

    setSensorValue: (state, action) => {
      const { id, value } = action.payload;

      // const sensors = state.RAS.questions[state.RAS.currentPage]?.sensors || {};
      // const sensorsKeys = Object.keys(sensors);
      // console.log(sensorsKeys);
      // const currentSensorKey = sensorsKeys.find(sensorsKey => sensors[sensorsKey]?.id === id);
      // const currentSensor = sensors[currentSensorKey] || {};
      // console.log(currentSensor);

      if (state.RAS.questions && state.RAS.questions[state.RAS.currentPage]?.sensors[id]) {
        state.RAS.questions[state.RAS.currentPage].sensors[id].valueResponse = value;
      }
    },

    setSetting: (state, action) => {
      const { name, value } = action.payload;
      state.settings[name] = value;

      // appStorage.setItem("settings", state.settings);
    },

    setSettings: (state, action) => {
      state.settings = action.payload;
    },

    setPatient: (state, action) => {
      state.patient = action.payload;
    },

    setPatientEdit: (state, action) => {
      state.patientEdit = action.payload;
    },

    resetSettings: (state) => {
      state.settings = initialState.settings;
      // appStorage.removeItem("settings");
    },
    resetPatient: (state) => {
      state.patient = initialState.patient;
    },
    resetPatientEdit: (state) => {
      state.patientEdit = initialState.patientEdit;
    },

    addToPatientLifesAnamnez: (state, action) => {
      console.log("addToPatientLifesAnamnez", action);
      state.patient.lifesAnamnez = [
        ...state.patient.lifesAnamnez,
        action.payload
      ];
    },
    updateCurrentHistoryItem: (state, action) => {
      console.log("updateCurrentHistoryItem", action);
      state.currentHistoryItem = {
        ...state.currentHistoryItem,
        ...action.payload
      };
    },

    addToPatientDisease: (state, action) => {
      console.log("addToPatientDisease", action);
      state.patient.disease = [
        ...state.patient.disease,
        action.payload
      ];
    },

    addToPatientEditDisease: (state, action) => {
      console.log("addToPatientEditDisease", action);
      state.patientEdit.disease = [
        ...state.patientEdit.disease,
        action.payload
      ];
    },

    addToPatientEditLifesAnamnez: (state, action) => {
      console.log("addToPatientEditLifesAnamnez", action);
      state.patientEdit.lifesAnamnez = [
        ...state.patientEdit.lifesAnamnez,
        action.payload
      ];
    },

    addToClarificationsList: (state, action) => {
      state.symptomsList = state.symptomsList.map(item => {

        if (action.payload.item?.id === item.id) {
          if (!item.clarifications) {
            item.clarifications = [];
          }

          const payloadValue = action.payload.value || { name: "", id: "NO_PAYLIAD_VALUE" };
          const { name, id } = payloadValue;

          // Добавляем, только если ещё нет в списке
          if (item.clarifications.findIndex(item => item.id === id) === -1) {
            const newValue = {
              name,
              id,
              type: action.payload.type,
              unitname: null,
              value: 0,
              unitId: null,
            };

            item.clarifications.push(newValue);
          }
        }

        return item;
      });
    },

    addToUnitsList: (state, action) => {

      console.log(action.payload);
      const itemId = action.payload.item?.id || "NO_ID";

      if (state.unitsList[itemId]) {

        state.unitsList[itemId] = {
          ...state.unitsList[itemId],
          value: action.payload.value,
        };
      } else {
        state.unitsList[itemId] = {
          unit: {},
          value: action.payload.value,
        };
      }
    },

    addToUnitsListFromSearch: (state, action) => {

      console.log(action.payload);
      const itemId = action.payload.item?.id || "NO_ID";

      if (state.unitsList[itemId]) {

        state.unitsList[itemId] = {
          ...state.unitsList[itemId],
          unit: action.payload.value,
        };
      } else {
        state.unitsList[itemId] = {
          unit: action.payload.value,
          value: "",
        };
      }
    },

    addToPatientClarificationsList: (state, action) => {
      state.patient.disease = state.patient.disease.map(item => {

        if (action.payload.item?.id === item.id) {
          if (!item.clarifications) {
            item.clarifications = [];
          }

          const { name, id } = action.payload.value;

          // Добавляем, только если ещё нет в списке
          if (item.clarifications.findIndex(item => item.id === id) === -1) {
            const newValue = {
              name,
              id,
              type: action.payload.type,
              unitname: null,
              value: 0,
              unitId: null,
            };

            item.clarifications.push(newValue);
          }
        }

        return item;
      });
    },

    addToPatientEditClarificationsList: (state, action) => {
      state.patientEdit.disease = state.patientEdit.disease.map(item => {

        if (action.payload.item?.id === item.id) {
          if (!item.clarifications) {
            item.clarifications = [];
          }

          const { name, id } = action.payload.value;

          // Добавляем, только если ещё нет в списке
          if (item.clarifications.findIndex(item => item.id === id) === -1) {
            const newValue = {
              name,
              id,
              type: action.payload.type,
              unitname: null,
              value: 0,
              unitId: null,
            };

            item.clarifications.push(newValue);
          }
        }

        return item;
      });
    },

    addToPatientEditLifesAnamnezClarificationsList: (state, action) => {
      state.patientEdit.lifesAnamnez = state.patientEdit.lifesAnamnez.map(item => {

        if (action.payload.item?.id === item.id) {
          if (!item.clarifications) {
            item.clarifications = [];
          }

          const { name, id } = action.payload.value;

          // Добавляем, только если ещё нет в списке
          if (item.clarifications.findIndex(item => item.id === id) === -1) {
            const newValue = {
              name,
              id,
              type: action.payload.type,
              unitname: null,
              value: 0,
              unitId: null,
            };

            item.clarifications.push(newValue);
          }
        }

        return item;
      });
    },

    removeClarificationById: (state, action) => {
      state.symptomsList = state.symptomsList.map(item => {

        if (action.payload.item?.id === item.id) {
          if (item.clarifications?.length) {
            item.clarifications = item.clarifications.filter(clarifacation => clarifacation.id !== action.payload.clarificationId);
          }
        }
        return item;
      });
    },

    removePatientClarificationById: (state, action) => {
      state.patient.disease = state.patient.disease.map(item => {

        if (action.payload.item?.id === item.id) {
          if (item.clarifications?.length) {
            item.clarifications = item.clarifications.filter(clarifacation => clarifacation.id !== action.payload.clarificationId);
          }
        }
        return item;
      });
    },

    removePatientEditClarificationById: (state, action) => {
      state.patientEdit.disease = state.patientEdit.disease.map(item => {

        if (action.payload.item?.id === item.id) {
          if (item.clarifications?.length) {
            item.clarifications = item.clarifications.filter(clarifacation => clarifacation.id !== action.payload.clarificationId);
          }
        }
        return item;
      });
    },

    removePatientEditLifesAnamnezClarificationById: (state, action) => {
      state.patientEdit.lifesAnamnez = state.patientEdit.lifesAnamnez.map(item => {

        if (action.payload.item?.id === item.id) {
          if (item.clarifications?.length) {
            item.clarifications = item.clarifications.filter(clarifacation => clarifacation.id !== action.payload.clarificationId);
          }
        }
        return item;
      });
    },

    removeSymptomById: (state, action) => {
      state.symptomsList = state.symptomsList.filter(item => item.id !== action.payload);
    },

    removePatientDiseaseById: (state, action) => {
      state.patient.disease = state.patient.disease.filter(item => item.id !== action.payload);
    },

    removePatientEditDiseaseById: (state, action) => {
      state.patientEdit.disease = state.patientEdit.disease.filter(item => item.id !== action.payload);
    },

    removePatientEditLifesAnamnezById: (state, action) => {
      state.patientEdit.lifesAnamnez = state.patientEdit.lifesAnamnez.filter(item => item.id !== action.payload);
    },
    resetSymptomsList: (state) => {
      state.symptomsList = initialState.symptomsList;
    },
    setCurrentSymptom: (state, action) => {
      state.currentSymptom = action.payload;
    },
    setCurrentHistoryItem: (state, action) => {
      state.currentHistoryItem = action.payload;
    },
    setCurrentPatientLifesAnamnez: (state, action) => {
      state.currentPatientLifesAnamnez = action.payload;
    },
    setCurrentPatientDisease: (state, action) => {
      state.currentPatientDisease = action.payload;
    },
    setCurrentPatientId: (state, action) => {
      state.currentPatientId = action.payload;
    },
    resetCurrentSymptom: (state) => {
      state.currentSymptom = initialState.currentSymptom;
    },
    resetCurrentPatientDisease: (state) => {
      state.currentPatientDisease = initialState.currentPatientDisease;
    },
    resetCurrentPatientLifesAnamnez: (state) => {
      state.currentPatientLifesAnamnez = initialState.currentPatientLifesAnamnez;
    },

  },

  extraReducers: (builder) => {
    builder







      .addCase(sendGetEvacuationData.pending, (state) => {
        state.status = "loading";
        state.loading.evacuationData = true;
      })

      .addCase(sendGetEvacuationData.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.evacuationData = false;

        console.log(action.payload);

        let responseData = {};
        let responseRows = action.payload?.rows || {};
        let responseColumns = action.payload?.columns || [];



        console.log(responseColumns);

        const fieldTypes = {
          Guid: "string",
          String: "string",
          DateTimeOffset: "date",

          // не используются
          number: "number",
          // date: "date",
          // dateTime: "dateTime",
          boolean: "boolean",
          singleSelect: "singleSelect",
        }

        responseColumns = responseColumns.map(column => {

          let type = column.fieldType;
          let valueOptions = [];

          if (column.values && column.values.length) {
            type = "singleSelect";
            valueOptions = column.values;
          }


          return {
            ...column,
            type: fieldTypes[type] || "string",
            valueGetter: column.fieldType === "DateTimeOffset" ? (value) => value && new Date(value) : null,
            cellClassName: () => column.editable ? "editable-cell" : "",
            valueOptions,
          }
        });




        /**
         * @shitfix Убрать когда будет заполняться на бэке
         */

        responseColumns = responseColumns.map(column => {
          return {
            ...column,
            type: column.field === "h300_referral" ? "singleSelect" : column.type,
            valueOptions: column.values === null && column.field === "h300_referral" ? ["ВМО", "ВМО1", "ВМО2", "ВПТГ", "ЦВМО", "ЦВМО*"] : column.valueOptions,
          }
        });



        //   if (column.value && column.value.length) {
        //     columnsToHide[column.field] = false;
        // }


        // valueOptions
        console.log(responseColumns);


        //Guid

        responseData = {
          "table": {
            "columns": responseColumns,
            "rows": responseRows,
            "paging": {
              "sort": {
                "field": "doctor",
                "value": "ASC"
              },
              "filter": {
                "field": "fio",
                "value": "Пе"
              },
              "page": 1,
              "perPage": 100,
              "count": 1000
            }
          }
        };
        // 2024-05-12T01:00:00+00:00
        // responseData = {
        //   "table": {
        //     "columns": [
        //       {
        //         "field": "h300_id",
        //         "headerName": "ID",
        //         "hidden": true,
        //         "editable": false,
        //         "sortable": false,
        //         "type": "number"
        //       },
        //       {
        //         "field": "fio",
        //         "headerName": "ФИО",
        //         "hidden": false,
        //         "editable": false,
        //         "sortable": true,
        //         "fieldType": "input"
        //       },
        //       {
        //         "field": "diagnosis",
        //         "headerName": "Диагноз",
        //         "hidden": false,
        //         "editable": true,
        //         "sortable": true,
        //         "type": "description",
        //         "width": "200",
        //         cellClassName: (params) =>
        //           "editable-cell",
        //       },
        //       {
        //         "field": "dataUbytya",
        //         "headerName": "Дата убытия",
        //         "hidden": false,
        //         "editable": true,
        //         "sortable": true,
        //         "type": "dateTime",
        //         "width": "200"
        //       },
        //       {
        //         "field": "status",
        //         "headerName": "Статус",
        //         "hidden": false,
        //         "editable": false,
        //         "sortable": true,
        //         "type": "input",
        //         "width": "200"
        //       }
        //     ],
        //     "rows": [
        //       {
        //         "h300_id": "111",
        //         "fio": "Петров Пётр",
        //         "doctor": "Иванов Иван",
        //         "status": "На эвакуации",
        //         "diagnosis": "Диарея перманентная",
        //         "dataUbytya": new Date()
        //       },
        //       {
        //         "h300_id": "222",
        //         "fio": "Василий",
        //         "doctor": "Иванов Иван",
        //         "status": "на стационаре",
        //         "diagnosis": "Кашель",
        //         "dataUbytya": new Date("2024-05-12T01:00:00+00:00")
        //       }
        //     ],
        //     "paging": {
        //       "sort": {
        //         "field": "doctor",
        //         "value": "ASC"
        //       },
        //       "filter": {
        //         "field": "fio",
        //         "value": "Пе"
        //       },
        //       "page": 1,
        //       "perPage": 100,
        //       "count": 1000
        //     }
        //   }
        // };

        console.log(responseData);


        state.evacuationData = responseData;
      })

      .addCase(sendGetEvacuationData.rejected, (state, action) => {
        state.status = "idle";
        state.loading.evacuationData = false;
      })



      .addCase(sendSaveSelected.pending, (state) => {
        state.status = "loading";
        state.loading.evacuationData = true;
      })

      .addCase(sendSaveSelected.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.evacuationData = false;

        state.evacuationData.table.rows = state.evacuationData?.table?.rows?.map(item => {
          return {
            ...item,
            h300_status: action.meta?.arg?.selected?.includes(item.h300_id) ? "Эвакуирован" : item.h300_status
          }
        })
      })

      .addCase(sendSaveSelected.rejected, (state, action) => {
        state.status = "idle";
        state.loading.evacuationData = false;
      })



      ;
  },
});

export const {
  addToSymptomsList,
  addToClarificationsList,
  addToUnitsList,
  addToUnitsListFromSearch,
  addToPatientDisease,
  addToPatientEditLifesAnamnez,
  addToPatientEditDisease,
  addToPatientClarificationsList,
  addToPatientEditClarificationsList,
  addToPatientEditLifesAnamnezClarificationsList,
  removeSymptomById,
  removeClarificationById,
  removePatientDiseaseById,
  removePatientEditDiseaseById,
  removePatientEditLifesAnamnezById,
  removePatientClarificationById,
  removePatientEditLifesAnamnezClarificationById,
  removePatientEditClarificationById,
  resetSymptomsList,
  resetSettings,
  resetPatient,
  resetPatientEdit,
  setCurrentSymptom,
  setCurrentPatientDisease,
  setCurrentPatientLifesAnamnez,
  setResultGraphId,
  setRASPage,
  setSensorValue,
  setSetting,
  setSettings,
  setPatient,
  setPatientEdit,
  setCurrentHistoryItem,
  setCurrentPatientId,
  resetCurrentSymptom,
  resetCurrentPatientDisease,
  resetCurrentPatientLifesAnamnez,
  updateCurrentHistoryItem,
} = evacuationSlice.actions;


export const selectEvacuationData = (state) => state.evacuation.evacuationData;
export const selectLoading = (state) => state.evacuation.loading.evacuationData;

export default evacuationSlice.reducer;
