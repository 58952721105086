import React, { memo, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
    Box,
    Card,
    Grid,
    Typography,
} from "@mui/material";

import PersonIcon from '@mui/icons-material/Person';

import {
    selectCurrentPatientId,
} from "../history/historySlice";

import { fields } from '../../app/thema';

import { Search } from "./components/Search";
import { Patient } from "./components/Patient";


const HistoryComponent = () => {
    const dispatch = useDispatch();

    let currentPatientId = useSelector(selectCurrentPatientId);

    const [loading, setLoading] = useState(false);


    return <Grid
        className="no-print"
        sx={{
            mt: '15px',
        }}
        container
    >
        <Grid
            item
            // xs={1}
            md={0}
            lg={1}
        />
        <Grid
            item
            md={12}
            lg={10}
        >
            <form>
                <Card
                    sx={{
                        mx: '5px',
                        py: '15px',
                        px: '10px',
                        borderRadius: '10px'
                    }}
                    variant="outlined"
                >
                    <Grid
                        container spacing={2}
                    >
                        <Grid
                            style={{
                                textAlign: 'center',
                            }}
                            item
                            xs={12}
                        >
                            <Typography
                                variant="h4"
                                color={fields.collection2.borderColor}
                            >
                                Этапы эвакуации
                            </Typography>

                        </Grid>


                        <Box sx={{ width: '100%', pl: 3, pr: 3 }}>
                            <Search
                                variant="parse"
                                type="TokenFIO"
                                label={"Личный номер или ФИО"}
                                itemIcon={<PersonIcon sx={{ color: 'text.secondary' }} />}
                                inputColor="#344C1129"
                                sx={{
                                    backgroundColor: "#fcfdfd",
                                }}
                                resultItemProps={{
                                    sx: {
                                        display: "inline-block",
                                        "&:first-letter": {
                                            textTransform: "uppercase",
                                        }
                                    }
                                }}

                            />

                            {currentPatientId ? <Patient /> : null}
                        </Box>

                    </Grid>
                </Card>
            </form>
        </Grid>
        <Grid
            item
            md={0}
            lg={1}
        />

    </Grid>;
}



export const History = memo(HistoryComponent);
