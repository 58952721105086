import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';

import { appStorage } from '../helpers';

import { headers, links, fields, buttons } from '../app/thema';
import { alertError, alertWarn } from '../common/helper/ToastAlert';
import { urlHome } from '../common/helper/UrlRouter';

import { IsCode200, IsCode503 } from '../common/api/QueryHandler';
import { Authorization, CheckKey } from '../common/api/Billing';

import { Grid, Typography } from '@mui/material';

import { TextFld, PasswordFld } from '../components/Fields';
import { WelcomeImg } from '../components/Images';
import { SubmitBtn } from '../components/Buttons';
import { Version } from '../components/Links';
import { CircularLeftPrg } from '../components/Progress';


/**
 * Виджет окна авторизации в системе
 * @param {Object} props параметры
 * @returns виджет
 */
export default function SignIn(props) {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const model = {
        welcomeImg: {
            src: '/img/logo512.png',
            width: '68px',
            alt: '',
        },
        header: {
            thema: headers.collection1,
            value: 'Пирогов+',
        },
        subHeader: {
            thema: headers.collection2,
            value: 'Система поддержки принятия решений',
        },
        email: {
            thema: fields.collection1,
            required: true,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'email',
            label: 'Логин',
        },
        key: {
            thema: fields.collection1,
            required: true,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'key',
            label: 'Ключ',
        },
        pass: {
            thema: fields.collection1,
            required: true,
            size: 'small',
            type: 'password',
            variant: 'outlined',
            name: 'pass',
            label: 'Пароль',
        },
        submit: {
            thema: buttons.collection1,
            variant: 'contained',
            value: 'вход',
        },
        version: {
            thema: links.collections1,
            href: '',
            fontSize: '14px',
            size: 'small',
            value: '1.0.0.34',
        },
        errors: {
            msgWarn: 'Неверно указан логин или пароль!',
        }
    }
    let context = {
        email: '',
        pass: '',
        key: '',
    }

    const handleSubmit = async (values) => {
        setLoading(true);


        /**
         * @shitfix
         * @description 
         * отключаем авторизацию
         * убрать этот блок после того как авторизация будет исправлена на сервере
         */

        // sessionStorage.setItem('authentication', JSON.stringify({
        //     idPersonnel: "res.result.idPersonnel",
        //     login: "admin",
        //     password: "VbibyFD1989",
        //     role: "chiefMedicalOfficer",
        // }));
        // setLoading(false);
        // navigate(urlHome);
        // return;

        /**
         * <==
         */



        // let res = await UserCheck(values.email, values.pass);
        let res = await Authorization(values.email, values.pass, values.key);
        // Валидация
        if (!IsCode200(res)) {
            if (IsCode503(res)) {
                alertError('Сервис недоступен!');
                setLoading(false);

                return;
            }

            appStorage.removeItem('authentication');
            alertWarn('Неверный логин или пароль!');
            setLoading(false);

            return;
        }

        let val = {
            idPersonnel: res.result.idPersonnel,
            login: res.result.login,
            password: res.result.password,
            key: res.result.key,
            role: res.result.role,
            authkey: res.result.authkey,
        };

        /**
         * Проверяем ключ
         */

        let resKey = await CheckKey(values.key, res.result.authkey);
        // Валидация
        if (!IsCode200(resKey)) {
            if (IsCode503(resKey)) {
                alertError('Неправильный ключ');
                setLoading(false);

                return;
            }

            appStorage.removeItem('authentication');
            alertWarn('Неправильный ключ');
            setLoading(false);

            return;
        }






        appStorage.setItem('authentication', val);
        setLoading(false);
        navigate(urlHome);
        window.location.reload();
    }

    return (
        <Grid
            sx={{
                minHeight: '100%',
                direction: 'row',
                justifyContent: 'center',
                alignItems: 'center',
            }}
            container
        >
            <Grid
                item xs={1} sm={3} md={3} lg={3} xl={4}
            />
            <Grid
                item xs={8} sm={6} md={6} lg={6} xl={4}
            >
                <Formik
                    onSubmit={handleSubmit} initialValues={context} validationSchema=''
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid
                                container
                            >
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                    item xs={12} sm={12} md={12} lg={12} xl={12}
                                >
                                    <WelcomeImg
                                        src={model.welcomeImg.src} width={model.welcomeImg.width} alt={model.welcomeImg.alt}
                                    />
                                </Grid>
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                    item xs={12} sm={12} md={12} lg={12} xl={12}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: '36px',
                                            textAlign: 'center',
                                            color: model.header.thema.color,
                                        }}>
                                        {model.header.value}
                                    </Typography>
                                </Grid>
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                    item xs={12} sm={12} md={12} lg={12} xl={12}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: '25px',
                                            textAlign: 'center',
                                            color: model.subHeader.thema.color,
                                        }}>
                                        {model.subHeader.value}
                                    </Typography>
                                </Grid>
                                <Grid
                                    sx={{
                                        paddingTop: '30px',
                                    }}
                                    item xs={12} sm={12} md={12} lg={12} xl={12}
                                >
                                    <TextFld
                                        required={model.email.required}
                                        thema={model.email.thema} name={model.email.name} size={model.email.size}
                                        type={model.email.type} variant={model.email.variant} label={model.email.label}
                                        value={values.email}
                                        handleBlur={handleBlur} handleChange={handleChange}
                                        touched={touched.email} error={errors.email}
                                    />
                                </Grid>
                                <Grid
                                    sx={{
                                        paddingTop: '20px',
                                    }}
                                    item xs={12} sm={12} md={12} lg={12} xl={12}
                                >
                                    <PasswordFld
                                        required={model.pass.required}
                                        thema={model.pass.thema} name={model.pass.name} size={model.pass.size}
                                        variant={model.pass.variant} label={model.pass.label} value={values.pass}
                                        handleBlur={handleBlur} handleChange={handleChange}
                                        touched={touched.pass} error={errors.pass}
                                    />
                                </Grid>
                                <Grid
                                    sx={{
                                        paddingTop: '30px',
                                    }}
                                    item xs={12} sm={12} md={12} lg={12} xl={12}
                                >
                                    <TextFld
                                        required={model.key.required}
                                        thema={model.key.thema} name={model.key.name} size={model.key.size}
                                        type={model.key.type} variant={model.key.variant} label={model.key.label}
                                        value={values.key}
                                        handleBlur={handleBlur} handleChange={handleChange}
                                        touched={touched.key} error={errors.key}
                                    />
                                </Grid>
                                <Grid
                                    sx={{
                                        paddingTop: '20px',
                                    }}
                                    item xs={6} sm={6} md={4} lg={2} xl={2}
                                >
                                    {loading === true ?
                                        <CircularLeftPrg /> :
                                        <SubmitBtn
                                            thema={model.submit.thema} variant={model.submit.variant} value={model.submit.value}
                                        />
                                    }
                                </Grid>
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                        paddingTop: '20px',
                                    }}
                                    item xs={6} sm={6} md={8} lg={10} xl={10}
                                >
                                    <Version
                                        thema={model.version.thema} fontSize={model.version.fontSize}
                                        size={model.version.size} value={model.version.value} href={model.version.href}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </Grid>
            <Grid
                item xs={1} sm={3} md={3} lg={3} xl={4}
            />
        </Grid>
    )
}
