import {
    ResponseCheck, GetFetchAsync, PostFetchAsync, PutFetchAsync, DeleteFetchAsync,
} from '../api/QueryHandler';
import {
    RegPersonnelPUT, FindByTokenNumberPOST, FindByCallSignPOST, FindByStringPOST, GenerateTokenNumberPOST,
} from '../helper/UrlController';
import {
    ResponseAlert, ItemFindByPersonnel
} from '../models/Response';
import {
    RequestRegPersonnel, RequestFindByTokenNumber, RequestFindByCallSign, RequestFindByString, RequestGenerateTokenNumber,
} from '../models/Request';


// REST API Контроллер взаимодействия с журналом личного состава

/**
 * API для добавления новой записи в журнале личного состава
 * @param { String } tokenNumber Номер жетона
 * @param { String } callSign Позывной
 * @param { String } surname Фамилия
 * @param { String } name Имя
 * @param { String } patronymic Отчество
 * @param { Date } birthday Дата рождения
 * @param { String } militaryUnit Воинская часть
 * @param { String } division Подразделение
 * @param { String } rota Рота
 * @param { String } platoon Взвод
 * @param { String } department Отделение
 * @param { String } militaryRank Военское звание
 * @returns Модель ответа
 */
async function RegPersonnelAsync(tokenNumber, callSign, surname, name, patronymic, birthday, phoneNumber, militaryUnit, division, 
    rota, platoon, department, militaryRank) {
    let model = {
        alert: {},
        result: {},
    };
    try {
        let req = new RequestRegPersonnel(
            tokenNumber, callSign, surname, name, patronymic, birthday, phoneNumber, militaryUnit, division, rota, platoon, 
            department, militaryRank
        ).getModel();
        let res = await PutFetchAsync(RegPersonnelPUT().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
        model.result = res.result;
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

/**
 * API для поиска записей по номеру жетона
 * @param { String } tokenNumber Номер жетона
 * @returns Модель ответа
 */
async function FindByTokenNumberAsync(tokenNumber) {
    let model = {
        alert: {},
        result: {},
    };
    try {
        let req = new RequestFindByTokenNumber(tokenNumber).getModel();
        let res = await PostFetchAsync(FindByTokenNumberPOST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
        model.result = res.results;
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

/**
 * API для генерации номера жетона
 * @returns Модель ответа
 */
async function GenerateTokenNumberAsync() {
    let model = {
        alert: {},
        value: "",
    };
    try {
        let req = new RequestGenerateTokenNumber().getModel();
        let res = await PostFetchAsync(GenerateTokenNumberPOST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
        model.value = res.value;
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

/**
 * API для поиска записей по позывному
 * @param { String } callSign Позывной
 * @returns Модель ответа
 */
async function FindByCallSignAsync(callSign) {
    let model = {
        alert: {},
        result: {},
    };
    try {
        let req = new RequestFindByCallSign(
            callSign
        ).getModel();
        let res = await PostFetchAsync(FindByCallSignPOST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }
        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
        model.result = ItemFindByPersonnel(
            res.result.id, res.result.fullName
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

/**
 * API для поиска записи по Номеру жетона или ФИО
 * @param { String } value Строка для анализа
 * @returns Модель ответа
 */
async function FindByStringAsync(value) {
    let model = {
        alert: {},
        result: {},
    };
    try {
        let req = new RequestFindByString(
            value
        ).getModel();
        let res = await PostFetchAsync(FindByStringPOST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }
        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
        model.result = res.results;
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;   
}


export {
    RegPersonnelAsync, FindByTokenNumberAsync, FindByCallSignAsync, FindByStringAsync, GenerateTokenNumberAsync
}