import React from 'react';
import { useNavigate } from 'react-router-dom';

import { appStorage } from '../helpers';

import { drawers } from '../app/thema';
import {
    urlHome, urlDocuments, urlSignOut, urlExport, urlHistory, urlRecent, urlPatientRegistration, urlManagement, urlEvacuation,
} from '../common/helper/UrlRouter';
import { ExportByAllToCsv } from '../common/helper/Export';
import { Drawer, Box, List, Divider } from '@mui/material';
import { DividerDwr, ItemListDwr, ItemCollapseListDwr } from '../components/Drawers';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import CommuteOutlinedOutlineIcon from '@mui/icons-material/CommuteOutlined';
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettingsOutlined';

import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';



/**
 * Виджет левого меню
 * @param { Object } props передаваемые параметры
 * @returns виджет
 */
export default function LeftMenu(props) {
    const navigate = useNavigate();

    //    admin
    //    medicalPlatoon
    //    medicalCompany
    //    medicalBattalion
    //    militaryHospital
    //    nachmedCorpus
    //    nachmedRegion
    //    medicalRegistrator

    const items = [
        {
            icon: <HomeOutlinedIcon />,
            text: 'Главная',
            url: urlRecent,
            accesss: ["*"],
        },
        {
            icon: <PendingActionsOutlinedIcon />,
            text: 'Приемно-сортировочное отделение',
            url: urlPatientRegistration,
            accesss: ["admin", "medicalPlatoon", "medicalCompany", "medicalBattalion", "militaryHospital", "medicalRegistrator"],
        },
        {
            icon: <CommuteOutlinedOutlineIcon />,
            text: 'Эвакуационное отделение',
            url: urlEvacuation,
            accesss: ["admin", "medicalCompany", "medicalBattalion", "militaryHospital", "nachmedCorpus", "nachmedRegion", "medicalRegistrator"],
        },
        {
            icon: <PortraitOutlinedIcon />,
            text: 'Этапы эвакуации',
            url: urlHistory,
            accesss: ["admin", "medicalCompany", "medicalBattalion", "militaryHospital", "nachmedCorpus", "nachmedRegion", "medicalRegistrator"],
        },
        {
            component: <Divider />,
            accesss: ["*"],
        },
        {
            icon: <FileDownloadOutlinedIcon />,
            text: 'Экспорт в CSV',
            url: urlExport,
            accesss: ["admin", "medicalCompany", "medicalBattalion", "militaryHospital", "nachmedCorpus", "nachmedRegion"],
        },
        {
            icon: <AdminPanelSettingsIcon />,
            text: 'Управление сервером',
            url: urlManagement,
            accesss: ["admin"],
        },
        {
            component: <Divider />,
            accesss: ["*"],
        },
        {
            icon: <LogoutOutlinedIcon />,
            text: 'Выход',
            url: urlSignOut,
            accesss: ["*"],
            callback: () => { appStorage.removeItem('authentication'); },
        },
    ];

    const roleItems = items.filter(item => item.accesss?.includes(props.role) || item.accesss?.includes("*"));

    const MenuItem = (itemProps) => {
        const {
            text,
            icon,
            url,
            disabled = false,
            thema = drawers.collection1,
            component,
            callback,
        } = itemProps.item;

        if (component) {
            return component;
        }

        const handleClickItem = (event) => {
            callback && callback();
            props.handleClick && props.handleClick();
            url && navigate(url);
            event.preventDefault();
        }

        return <ItemListDwr
            key={text}
            url={url}
            disabled={disabled}
            thema={thema}
            icon={icon}
            text={text}
            handleClickItem={handleClickItem}
        />;
    }

    return (
        <Drawer
            open={props.open}
            onClose={props.handleClick}
        >
            <Box
                sx={{
                    width: 300
                }}
                role='presentation'
            >
                <List>
                    {roleItems.map(item => <MenuItem key={item.text} item={item} />)}
                </List>
            </Box>
        </Drawer>
    )
}